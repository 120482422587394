<template>
  <div>
    <section onmousedown="return false" onselectstart="return false">
      <b-row class="text-center pt-4 w-100">
        <h2 class="pink fw-bolder"><b>Plans</b></h2>
        <hr style="width: 18%; margin-left: 40%; margin-top: 5px" />
      </b-row>
      <!-- <marquee scrollamount="10" class="position-absolute" behavior="scroll" direction="right"><img src="/img/santa.gif"></marquee> -->
      <b-row class="text-center w-100">
        <b-col cols="12" class="text-lg-center">
          <p style="font-weight: 700; font-size: 1.2rem">
            7 Days full-featured trial, No credit card, No Payment.<br />
          </p>
          <!-- <div class=" shadow border-radius d-inline-block p-2 mb-3  text-center">
            <Countdown  class="text-center  text-blue blueclass mb-2 " deadline="December 31, 2021"></Countdown>
          </div> -->
        </b-col>
      </b-row>

      <div class="rowinline row g-0 ml-lg-5 mr-lg-5 pr-2 pl-2">
        <VueSlickCarousel class="ml-lg-0" v-bind="settingco" v-if="userlistdisplay && userlistdisplay.length > 0">
          <b-col v-for="(list, index) in userlistdisplay" :key="index" :class="{
            background_effect: !((index + 0) % 2),
          }" sm="11" class="shadow p-3 mb-5 rounded p-3 text-center">
            <b-row>
              <b-col class="text-center" v-if="list.id != 16">
                <h2 class="" style="font-weight: 700">{{ list.plan_name }}</h2>
                <h4 class="marginb" v-if="list.id == 1">Free</h4>
                <h5 v-else class="text-center size-4" style="font-size: 4rem">
                  ₹ {{ list.plan_price }} <b style="font-size: 1rem">INR</b><br />
                  <b style="
                      font-size: 1rem !important;
                      font-weight: 300 !important;
                    ">
                    <b class="line-through" style="font-weight: 300 !important; text-decoration: "><del>₹ {{
                        list.discount_price
                    }} </del></b>*
                  </b>
                </h5>
                <br />
              </b-col>
              <b-col class="text-center" v-if="list.id == 16">
                <h2 class="" style="font-weight: 700">{{ list.plan_name }}</h2>

                <br />
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="text-left">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="clock" aria-hidden="true"></b-icon>
                      Validity
                    </b-col>
                    <b-col sm="4"><span>{{ list.plan_period }} {{ list.plan_period_type }}
                      </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="text-left" v-if="list.id == 1">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span>{{ list.countriescount }} </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>
              <b-col class="text-left curser-pointer" v-if="list.id == 4">
                <p>
                  <b-row class="abc" id="tooltip-target-2" v-b-modal.modal-goldcountries>
                    <b-col sm="8">
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      <b-icon icon="map" aria-hidden="true" class="tooltip tooltip-scroll"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span>{{ list.countriescount }} + </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>
              <b-col class="text-left curser-pointer" v-if="list.id == 3">
                <p>
                  <b-row id="tooltip-target-1" v-b-modal.modal-silvercountries>
                    <b-col sm="8">
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      <b-icon icon="map" aria-hidden="true" class="tooltip tooltip-scroll"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span>{{ list.countriescount }} + </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>

              <b-col class="text-left curser-pointer" v-if="list.id == 5">
                <p>
                  <b-row v-b-modal.modal-platiniumcountries>
                    <b-col>
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      <b-icon sm="8" icon="map" aria-hidden="true" class="tooltip tooltip-scroll"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span>{{ list.countriescount }} + </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>
              <b-col class="text-left" v-if="list.id == 14">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span> India </span> </b-col>
                  </b-row>
                </p>
              </b-col>
              <b-col class="text-left" v-if="list.id == 16">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="map" aria-hidden="true"></b-icon>
                      Countries
                    </b-col>
                    <b-col sm="4"><span> Customized </span> </b-col>
                  </b-row>
                </p>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="text-left">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                      Premium Contacts
                    </b-col>
                    <b-col sm="4"><span>{{ list.prem_contact }} </span>
                    </b-col>
                  </b-row>
                </p>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="text-left">
                <p>
                  <b-row>
                    <b-col sm="8">
                      <b-icon icon="headset" aria-hidden="true"></b-icon>
                      Support
                    </b-col>
                    <b-col sm="4">24/7 </b-col>
                  </b-row>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-white btn-three34">
                <span v-if="isLoggedIn && list.id != 1 && list.id != 16" class="text-white" :v-model="cartvalue">
                  <a href="javascript:;" @click="goToCart(list.id)">Buy Now</a></span>
                <span v-if="!isLoggedIn && list.id == 1" v-b-modal.modal-1>Try Now
                </span>
                <span v-if="!isLoggedIn && list.id != 1" v-b-modal.modal-1>Buy Now
                </span>
                <span v-if="isLoggedIn && list.id == 16" v-b-modal.modal-customplanmodel>Buy Now
                </span>
              </b-col>
              <div v-if="list.id == 5" class="" id="Inquiry"></div>
            </b-row>
          </b-col>
        </VueSlickCarousel>
      </div>
      <div id="testimonial"></div>
    </section>
    <!-- plans countries list  -->
    <!-- silver plan countrie  -->
    <b-modal size="ls" id="modal-silvercountries" modal-class="bluetitle" title="Silver Plan Countries" hide-footer>
      <b-row>
        <b-col class="bluetitle">
          <b>Gulf</b>
        </b-col>
        <b-col> UAE,Syria,Bahrain,<br />Lebanon</b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>East Africa</b>
        </b-col>
        <b-col>
          KENYA, Uganda, Madagascar, Ethiopia, Djibouti, Malawi, Zambia, Rwanda,
          Zimbabwe, Mauritius, Botswana, Burundi, Sudan, Seychelles
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>West Africa</b>
        </b-col>
        <b-col>
          Naminia , Gabon , Nigeria ,<br />Cameroon , Benin , Mali , Burkina ,
          <br />Faso,Gambia,Nigeria
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Pacific</b>
        </b-col>
        <b-col> Cambodia,Malaysia,Papua New Guinea, Maldives </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Bulkan & Europe</b>
        </b-col>
        <b-col>
          Bulgaria, Slovenia, Latvia, Austria, Hungary, Cyprus, Lithuania,
          Belarus, Ukraine
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>South America</b>
        </b-col>
        <b-col>
          Uruguay, Paraguay, Bolivia, Suriname, Ecuador, Nicaragua
        </b-col>
      </b-row>
    </b-modal>
    <!-- Gold plan countries  -->
    <b-modal size="ls" id="modal-goldcountries" modal-class="bluetitle" title="Gold Plan Countries" hide-footer>
      <b-row>
        <b>Silver Plan Countries +</b><br />
        <hr />
        <b-col class="bluetitle">
          <b>Gulf</b>
        </b-col>
        <b-col> Saudi Arabia, Yemen, Qatar, Jordan</b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>East Africa</b>
        </b-col>
        <b-col> Mozambique, Tanzania </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>West Africa</b>
        </b-col>
        <b-col>
          Angola, Republic Of Congo, Dr.Congo,<br />
          Togo, Ghana, Cote D'Ivoire, <br />Mauritania, Guinea, Morocco, Algeria
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Pacific</b>
        </b-col>
        <b-col> Philippines </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Bulkan & Europe</b>
        </b-col>
        <b-col>
          Portugal, Greece, Serbia, France, Macedonia, Netherlands, Bosnia
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>South America</b>
        </b-col>
        <b-col> Chile, Brazil, Peru, Costa Rica, Panama </b-col>
      </b-row>
    </b-modal>
    <!-- Platinium plan countries  -->
    <b-modal size="ls" id="modal-platiniumcountries" modal-class="bluetitle" title="Platinium Plan Countries"
      hide-footer>
      <b-row>
        <b>Gold Plan Countries +</b><br />
        <hr />
        <b-col class="bluetitle">
          <b>North America</b>
        </b-col>
        <b-col> USA, Canada </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Gulf</b>
        </b-col>
        <b-col> Kuwait, Iraq, Israel/Palestine, Oman</b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>East Africa</b>
        </b-col>
        <b-col> South Africa </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>West Africa</b>
        </b-col>
        <b-col> Tunisia, Libya, Senegal,Liberia, Sierra Leone </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Pacific</b>
        </b-col>
        <b-col> Thailand, Taiwan, Indonesia, New Zeland, Australia </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>Bulkan & Europe</b>
        </b-col>
        <b-col>
          Kosovo, UK, Germany, Belgium, Albania, Poland,<br />
          Romania, Croatia, Italy, Spain, Ireland , Russia
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="bluetitle">
          <b>South America</b>
        </b-col>
        <b-col>
          Mexico, Colombia, Guatemala,<br />
          Haiti, Dominican Republic
        </b-col>
      </b-row>

    </b-modal>
    <!-- custom plan logic and popup -->
    <b-modal label="Select Customized Country Data" size="lg" class="modal fade" ref="customplanmodel"
      id="modal-customplanmodel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" @ok="handleOk">
      <div>
        <div>
          <form ref="form" @submit.stop.prevent="AddInCart">
            <p style="color: red">{{ this.dataplan.customselectmessage }}</p>
            <label for="">Select Which Country Data You Want </label>
            <multiselect v-model="dataplan.value" tag-placeholder="Select Country" placeholder="Search or add a tag"
              label="name" :custom-label="customLabel" track-by="code" :options="Countryoptions123" :multiple="true"
              :taggable="true" required></multiselect>
          </form>
        </div>
      </div>
    </b-modal>
    <!-- custom plan logic ends  -->
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Multiselect from "vue-multiselect";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Countdown from "vuejs-countdown";
export default {
  components: {
    VueSlickCarousel,
    Multiselect,
  },
  data() {
    const now = new Date();
    const newYear = "26-12-2021";

    return {
      time: newYear - now,
      settingco: {
        arrows: false,

        focusOnSelect: true,
        infinite: true,
        // "fade": true,
        autoplay: true,

        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      max: 10,
      disabled: 0,
      loading: false,
      userlistdisplay: null,
      success: null,
      cartvalue: null,
      error: null,
      validationErrors: null,
      Countryoptions123: [],

      dataplan: {
        value: null,
        planid: 16,
        customselectmessage: null,
      },
      popup: 0,
      localStoragePopup: 0,
      slide: 0,
      sliding: null,
    };
  },

  computed: {
    ...mapGetters(["user", "errors"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },

  created() {
    this.fetchplanDynamic();
    this.fetchcountry();
  },
  mounted() {
    if (this.popup != 1) {
      window.setInterval(() => {
        this.onWindowLoad();
      }, 3000);
    } else {
      this.closepopup();
    }
    if (this.$route.params.params == "verify") {
      alert(this.$route.params.params);
    } else {
      // alert(this.$route.params);
    }
  },

  methods: {
    ...mapActions([
      "getUserData",
      "sendLogoutRequest",
      "addToCart12",
      "sendInquiryE",
      "AllotedPlanFind",
      "CountryDropDynamic",
    ]),
    fetchcountry() {
      this.CountryDropDynamic().then((resp) => {
        let arr = [];
        resp.data.data.forEach((res) => {
          arr.push({ name: res.country_name, code: res.id, price: res.id });
        });
        this.Countryoptions123 = arr;
        // this.Countryoptions123=resp.data.data
      });
    },

    customLabel({ name, price }) {
      return `${name} — [Rs - ${price}]`;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    goToCart(pid) {
      let user = localStorage.getItem("user");
      this.token = localStorage.getItem("token");

      this.addToCart12({ id: pid, user_id: user }).then((response) => {
        window.location.href = "/cart";
      });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.AddInCart();
    },
    AddInCart() {
      if (this.dataplan.value.length > 2) {
        this.customselectmessage = "";
        let user = localStorage.getItem("user");
        let token = localStorage.getItem("token");

        let planid = this.dataplan.planid;
        this.addToCart12({
          id: planid,
          user_id: user,
          value: this.dataplan.value,
        }).then((response) => {
          window.location.href = "/cart";
        });
      } else {
        this.dataplan.customselectmessage = "Select Minimum 3 Countries.";
      }
    },
    fetchplanDynamic() {
      axios.get(process.env.VUE_APP_API_URL + "PlanList").then((resp) => {
        this.userlistdisplay = resp.data.planlist;
      });
    },
  },
};
</script>
<style scoped>
.batn {
  background: #1e38a7;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.batn:hover {
  background: #002ef8;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.background_effect:nth-child(odd) {
  background: #1e38a7 !important;
  color: #f8fbfd;
}

.background_effect:nth-child(odd) span:hover a {
  color: white;
}

.background_effect:nth-child(even) span:hover a {
  color: #fa2b56;
}

.bg-pink {
  background-color: #fa2b56;
}

.pink {
  color: #fa2b56;
}

.btn-three34 span a {
  text-decoration: none;
}

.btn-three34 span {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  border-radius: 25px;
}

.btn-three34:hover span {
  background: none;
  border: 1px solid #fa2b56;
  color: #fa2851 !important;
}
</style>
